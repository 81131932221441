import { Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexShrink: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    titleWrapper: {
      display: "flex",
      flexShrink: 1,
    },
    type: ({ isSmall }: ItemTitleProps) => ({
      color: green[600],
      fontSize: isSmall ? "0.75rem" : theme.typography.fontSize,
      fontWeight: 400,
    }),
    titleContainer: {
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        textAlign: "left",
      },
    },
    title: (props: ItemTitleProps) => ({
      fontSize: props.isSmall ? "0.825rem" : "1.5rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: props.isSmall ? "1.15rem" : undefined,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.15rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.h6.fontSize,
      },
      fontWeight: 700,
    }),
    base: {
      color: theme.palette.primary.main,
    },
    check: {
      color: theme.palette.secondary.main,
    },
    star: {
      display: "flex",
      fontSize: 8,
      textAlign: "center",
      verticalAlign: "center",
      lineHeight: 2,
      margin: theme.spacing(0, 0.5),
      color: theme.palette.warning.main,
      // [theme.breakpoints.up("md")]: {
      //   margin: theme.spacing(0, 1),
      // },
    },
  }),
);

export type ProductItemType = "basic" | "enhanced" | "plus";

interface ItemTitleProps {
  title: string;
  isSmall?: boolean;
  itemType?: ProductItemType;
}

const ItemTitle = (props: ItemTitleProps) => {
  const { title, itemType } = props;
  const classes = useStyles(props);
  const checkMatch = title.match(/check/i);
  const base =
    checkMatch && checkMatch.index
      ? title.substr(0, checkMatch.index)
      : itemType
      ? title.replace(itemType, "")
      : title;

  return (
    <div className={classes.root}>
      <Typography
        component="span"
        variant="h6"
        className={clsx([classes.title, classes.base])}
      >
        {base}
      </Typography>
      {checkMatch ? (
        <Typography
          component="span"
          variant="h6"
          className={clsx([classes.title, classes.check])}
        >
          Check
        </Typography>
      ) : null}
      {itemType ? <StarIcon className={classes.star} /> : null}
      {itemType ? (
        <Typography
          component="span"
          variant="h6"
          className={clsx([classes.type])}
        >
          {itemType}
        </Typography>
      ) : null}
    </div>
  );
};

export default ItemTitle;
