import Image from "next/image";
import { Container, Fade, useMediaQuery } from "@material-ui/core";
import { styled, StyledProps, useTheme } from "@material-ui/core/styles";
import React from "react";
import showcaseData from "../product/product-showcase-data.json";
import { imageMap } from "./HeroImages";

const HERO_DEFAULTS = {
  textOverlay: {
    lg: "Large/Text.png",
    md: "Medium/Text.png",
    sm: "Small/Text.png",
  },
  image: {
    alt: 'Several folders reading "Insurance", "Fraud", "Claims"',
    lg: "Large/Default.png",
    md: "Medium/Default.png",
    sm: "Small/Default.png",
  },
};

export interface ImageProps {
  src: string;
  alt: string;
}

export type SrcSet = {
  alt: string;
  src: string;
  sm: string;
  md: string;
  lg: string;
};

export type RegisteredImageMap = {
  [key: string]: SrcSet;
} & {
  default: SrcSet;
};

export interface IHeroContext {
  key: string | null;
  srcSet: SrcSet;
  images: RegisteredImageMap;

  setCurrentImage(key: string | null): void;
}

const heroDefaultSrcSet: SrcSet = {
  alt: HERO_DEFAULTS.image.alt,
  src: HERO_DEFAULTS.image.sm,
  lg: HERO_DEFAULTS.image.lg,
  md: HERO_DEFAULTS.image.md,
  sm: HERO_DEFAULTS.image.sm,
};

const images: RegisteredImageMap = showcaseData.reduce(
  (result: RegisteredImageMap, entry) => {
    result[entry.title] = entry.image;
    return result;
  },
  {
    default: heroDefaultSrcSet,
  },
);

export const HeroContext = React.createContext<IHeroContext>({
  key: "default",
  srcSet: heroDefaultSrcSet,
  images,
  setCurrentImage() {
    throw Error("setImage is not implemented");
  },
});

export const HeroProvider = ({ children }: React.PropsWithChildren<any>) => {
  // todo: update to set based on initial screen size
  const [current, setCurrent] = React.useState<string>("default");

  const setCurrentImage = (key: string | null) => {
    if (!key) return setCurrent("default");

    if (images.hasOwnProperty(key)) {
      return setCurrent(key);
    }
  };
  const srcSet = images[current];

  return (
    <HeroContext.Provider
      value={{ key: current, srcSet, images, setCurrentImage }}
    >
      {children}
    </HeroContext.Provider>
  );
};

interface HeroImageNextProps {
  title: keyof typeof imageMap;
}

const getImageMap = (title: keyof typeof imageMap) => imageMap[title];

const HeroImageNext = styled(({ title, ...props }: HeroImageNextProps) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const medium = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const large = useMediaQuery(theme.breakpoints.up("lg"));
  const map = getImageMap(title);
  const imageMapKey = large ? "lg" : medium ? "md" : "sm";
  const src = map[imageMapKey];

  return small || medium || large ? (
    <Image
      layout="fill"
      objectFit="cover"
      objectPosition="bottom"
      src={src}
      placeholder="blur"
      priority
      quality={80}
      alt=""
      {...props}
    />
  ) : null;
})({
  margin: 0,
  width: "100%",
});

const HeroTextImageNext = styled((props: StyledProps) => {
  const theme = useTheme();
  // const small = useMediaQuery(theme.breakpoints.down("md"));
  const medium = useMediaQuery(theme.breakpoints.between("sm", "lg"), {
    noSsr: true,
  });
  const large = useMediaQuery(theme.breakpoints.up("lg"), {
    noSsr: true,
  });
  const { lg: textLg, md: textMd, sm: textSm } = getImageMap("text");

  return (
    <Image
      alt=""
      src={large ? textLg : medium ? textMd : textSm}
      layout="fill"
      objectFit="contain"
      objectPosition="center"
      className={props.className}
    />
  );
})({
  margin: 0,
  position: "absolute",
  width: "100%",
});

export const Hero = styled(props => {
  const context = React.useContext(HeroContext);

  return (
    <Container maxWidth="lg">
      <div className={props.className}>
        {Object.entries(context.images).map(([key]) => {
          return (
            <Fade
              in={key === context.key}
              key={key}
              timeout={750}
              appear={false}
              mountOnEnter
            >
              <div>
                <HeroImageNext title={key as keyof typeof imageMap} />
              </div>
            </Fade>
          );
        })}
        <HeroTextImageNext />
      </div>
    </Container>
  );
})(({ theme }) => ({
  zIndex: -1,
  margin: theme.spacing(0, -1),
  display: "block",
  overflow: "hidden",
  position: "relative",
  boxSizing: "border-box",
  padding: 0,
  height: 200,
  [theme.breakpoints.up("sm")]: {
    height: 400,
  },
}));
