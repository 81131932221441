import { HeroContext, ImageProps } from "@Components/layouts/Hero";
import useAuthorization from "@Hooks/useAuth";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Link,
  Typography,
  useMediaQuery,
  Theme,
} from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  StyleRulesCallback,
} from "@material-ui/core/styles";
import { Product } from "@Types";
import React from "react";
import { signIn } from "src/context/AuthProvider";
import ProductListItem from "./ProductListItem";
import productShowcaseDataArray from "./product-showcase-data.json";
import useProductShowcaseData, {
  ShowcaseProduct,
} from "@Hooks/useProductShowcaseData";
import { FontWeight } from "theme/fonts";

interface ProductShowcaseData {
  title: string;
  description: string;
  image: ImageProps;
  products: Product[];
  order: number;
}

// @ts-ignore
const SHOWCASE_DATA: ProductShowcaseData[] = productShowcaseDataArray;

// Login item
const useLoginStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(2),
      },
      text: {
        marginBottom: theme.spacing(2),
        textAlign: "center",
        flexGrow: 1,
        fontSize: "0.975rem",
        fontWeight: FontWeight.Bold,
        color: theme.palette.primary.main,
      },
      button: {
        width: "100%",
      },
    }),
  { classNamePrefix: "LoginShowcaseItem" },
);

const LoginShowcaseItem = () => {
  const classes = useLoginStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography className={classes.text}>TO SEE OUR SERVICES</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => signIn({ returnUrl: "/" })}
          color="secondary"
          className={classes.button}
        >
          LOGIN OR REGISTER
        </Button>
      </Grid>
    </Grid>
  );
};

// Card
const cardStyles: StyleRulesCallback<Theme, ProductCategoryCardProps> =
  theme => ({
    root: {
      display: "flex",
      margin: "auto",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      maxWidth: 300,
      height: 450,
      [theme.breakpoints.up("md")]: {
        maxWidth: 225,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 255,
        height: 475,
      },
      overflow: "hidden",
      borderRadius: theme.shape.borderRadius,
    },
    titleBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
      flexShrink: 0,
      height: 75,
      padding: theme.spacing(0, 1),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      fontWeight: FontWeight.Bold,
      fontSize: ".95rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "1.1rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1.25rem",
      },
    },
    descriptionText: {
      overflow: "hidden",
      maxHeight: 150,
      margin: theme.spacing(0, 2),
      padding: theme.spacing(0),
      fontSize: theme.typography.body2.fontSize,
      [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(1),
      },
    },
    linkText: {
      fontSize: "0.9rem",
      margin: theme.spacing(1, 0),
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    productItemContainer: {
      // display: "flex",
      // flexDirection: "row",
      flexDirection: "column",
      alignSelf: "flex-end",
      width: "100%",
      flexGrow: 0,
      flexShrink: 1,
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      textAlign: "center",
      margin: theme.spacing(0),
      justifyContent: "space-around",
      width: "100%",
    },
  });

const useCardStyles = makeStyles(cardStyles, {
  name: "ShowcaseCard",
  classNamePrefix: "ServiceCard",
});

const showcaseProductToProduct = (scproduct: ShowcaseProduct): Product => {
  return {
    Id: `${scproduct.ProductId}`,
    Active: true,
    Name: scproduct.Name,
    SortOrder: scproduct.SortOrder,
    ProductTypeName: scproduct.CategoryName,
    Price: scproduct.Price,
    Description: "",
    IsExtended: false,
    IsMedical: scproduct.IsMedical,
  };
};

interface ProductCategoryCardProps {
  title: string;
  description: string;
  products: ShowcaseProduct[];
  authorized: boolean;
}

const ProductCategoryCard = (props: ProductCategoryCardProps) => {
  const { title, description, products, authorized } = props;
  const classes = useCardStyles(props);
  // @ts-ignore
  const heroContext = React.useContext(HeroContext);
  // const classes = useCardStyles();

  const handleMouseEnter = () => {
    if (title === heroContext.key) return;
    heroContext.setCurrentImage(title);
  };

  const handleMouseLeave = () => {
    heroContext.setCurrentImage(null);
  };

  return (
    <Paper
      className={classes.root}
      elevation={4}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box className={classes.titleBox}>{title}</Box>
      <div className={classes.textContainer}>
        <Typography className={classes.descriptionText}>
          {description}
        </Typography>
        <Link href="/services" className={classes.linkText}>
          See All Services
        </Link>
      </div>
      <Box className={classes.productItemContainer}>
        {authorized ? (
          products
          .sort((a, b) => a.SortOrder - b.SortOrder)
          .map(p => (
            <ProductListItem
              isSmall
              item={showcaseProductToProduct(p)}
              key={p.ProductId}
            />
          ))
        ) : (
          <LoginShowcaseItem />
        )}
      </Box>
    </Paper>
  );
};

// Container element
const useContainerStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(-5),
        zIndex: 2,
        padding: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
          padding: "unset",
        },
      },
    }),
  { classNamePrefix: "ProductCategoryShowcase" },
);

export const ProductCategoryShowcase = () => {
  const classes = useContainerStyles();
  const { client } = useAuthorization();
  const authorized = Boolean(client);
  const { data: showcaseData, mutate } = useProductShowcaseData();

  React.useEffect(() => {
    mutate();
  }, [authorized, mutate]);

  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={smUp ? 2 : 1}>
        {showcaseData
          ? showcaseData
              .sort((a, b) => a.SortOrder - b.SortOrder)
              .map((v, k) => (
                <Grid item xs={6} md={3} key={k}>
                  <ProductCategoryCard
                    title={v.Name}
                    description={v.Description}
                    products={v.Products}
                    authorized={authorized}
                  />
                </Grid>
              ))
          : null}
      </Grid>
    </Container>
  );
};
