import React from "react";
import LinkButton from "../LinkButton";
import { makeStyles } from "@material-ui/core/styles";
import CartMenuButton from "../cart/CartMenuButton";
import AuthenticationMenu from "../AuthenticationMenu";
import {
  Button,
  Container,
  Grid,
  Hidden,
  Typography,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { grey, common } from "@material-ui/core/colors";
import { createStyles, alpha } from "@material-ui/core/styles";
import clsx from "clsx";
import ElevationScroll from "./ElevationScroll";
import FadeScroll from "./FadeScroll";
import AppDrawer from "./AppDrawer";
import { useRouter } from "next/router";
import { useUser } from "src/context/AuthProvider";
import Topbar from "./Topbar";
import { FontWeight } from "theme/fonts";

type StyleProps = {
  isHome: boolean;
};

const useStyles = makeStyles(theme =>
  createStyles({
    toolbar: {
      display: "flex",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      minHeight: 50,
      justifyContent: "space-between",
      padding: theme.spacing(0, 1),
    },
    nav: {
      flexGrow: 0,
      justifyContent: "flex-end",
      alignItems: "center",
      display: "flex",
      // maxWidth: 800
    },
    secondary: {
      padding: 0,
      minHeight: 75,
      backgroundColor: theme.palette.background.paper,
    },
    secondaryEnd: {
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-end",
      },
    },
    tertiary: (prop: StyleProps) => ({
      minHeight: 50,
      backgroundColor: alpha(
        theme.palette.background.paper,
        prop.isHome ? 0 : 1,
      ),
      padding: theme.spacing(0, 1),
    }),
    offset: (prop: StyleProps) => ({
      ...(theme.mixins.toolbar as any),
      height: prop.isHome ? 190 : 200,
      [theme.breakpoints.up("sm")]: {
        height: 120,
      },
      [theme.breakpoints.up("lg")]: {
        height: prop.isHome ? 120 : 200,
      },
    }),
    logo: {
      display: "flex",
      flexGrow: 1,
    },
    navLinkItem: {
      [theme.breakpoints.up("lg")]: {
        marginRight: theme.spacing(12),
      },
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(5),
      },
      [theme.breakpoints.up("xs")]: {
        marginRight: theme.spacing(2),
      },
      "&:last-child": {
        marginRight: theme.spacing(0),
      },
      lineHeight: 0.9,
      color: "inherit",
    },
    container: {
      display: "flex",
      alignItems: "center",
    },
    noLeft: {
      paddingLeft: 0,
    },
    noRight: {
      paddingRight: 0,
    },
    phoneNumber: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontSize: theme.typography.h6.fontSize,
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.h5.fontSize,
      },
    },
    companyName: (prop: StyleProps) => ({
      textAlign: "right",
      // marginRight: theme.spacing(1),
      color: prop.isHome ? common.white : grey[600],
    }),
    pageTitle: {
      fontWeight: FontWeight.Bold,
      fontSize: theme.typography.fontSize + 2,
      textAlign: "center",
    },
    pushRight: {
      justifyContent: "flex-end",
    },
    leftAlign: {
      textAlign: "left",
    },
    rightAlign: {
      textAlign: "right",
    },
    boldText: {
      fontWeight: "bold",
      fontSize: theme.typography.h6.fontSize,
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.h5.fontSize,
      },
    },
  }),
);

export interface BaseHeaderProps {
  menuComponent?: React.ReactNode;
  title?: string;
}

export const BaseHeader = (_props: BaseHeaderProps) => {
  const { push, pathname } = useRouter();
  const isHome = pathname === "/";
  const classes = useStyles({ isHome });
  const { user } = useUser();

  return (
    <>
      <ElevationScroll disable={isHome}>
        <AppBar position="fixed" color="transparent">
          <Topbar />
          <Toolbar className={classes.toolbar} variant="dense">
            <Container
              maxWidth="lg"
              className={clsx([classes.container, classes.pushRight])}
            >
              <Hidden smDown implementation="css">
                <nav className={classes.nav}>
                  <LinkButton className={classes.navLinkItem} href="/">
                    Home
                  </LinkButton>
                  <LinkButton className={classes.navLinkItem} href="/about">
                    About
                  </LinkButton>
                  <LinkButton className={classes.navLinkItem} href="/contact">
                    Contact
                  </LinkButton>
                  <LinkButton className={classes.navLinkItem} href="/services">
                    Services
                  </LinkButton>
                  <CartMenuButton className={classes.navLinkItem} />
                  {!user && (
                    <Button
                      className={classes.navLinkItem}
                      onClick={() =>
                        push({
                          pathname: "/api/login",
                          query: {
                            returnTo: "/register",
                            register: "true",
                          },
                        })
                      }
                    >
                      Register
                    </Button>
                  )}
                  <AuthenticationMenu className={classes.navLinkItem} />
                </nav>
              </Hidden>
              <Hidden mdUp implementation="css">
                <AppDrawer />
                <CartMenuButton
                  className={clsx([classes.navLinkItem, classes.pushRight])}
                />
              </Hidden>
            </Container>
          </Toolbar>
          <Toolbar className={classes.tertiary}>
            <Container maxWidth="lg">
              <Grid
                container
                justifyContent="space-between"
                className={clsx([classes.container, classes.pushRight])}
              >
                <Grid item xs={6}>
                  <FadeScroll threshold={55}>
                    <Typography
                      className={clsx([classes.pageTitle, classes.leftAlign])}
                    >
                      {_props.title}
                    </Typography>
                  </FadeScroll>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={clsx(classes.rightAlign, classes.companyName)}
                  >
                    Trace America LLC.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar className={classes.offset} />
    </>
  );
};

export default BaseHeader;
