import { AxiosResponse } from "axios";
import useRequest from "./useRequest";

export interface ShowcaseProduct {
  CategoryName: string;
  CategoryId: string;
  Name: string;
  Price: number;
  ProductId: number;
  SortOrder: number;
  IsMedical: boolean;
}

export interface ShowcaseCategory {
  Id: number;
  Name: string;
  Description: string;
  ImageAlt: string;
  ImageName: string;
  Products: ShowcaseProduct[];
  SortOrder: number;
}

export interface UseProductShowcaseDataResult {
  data?: ShowcaseCategory[];
  mutate: (response?: AxiosResponse<ShowcaseCategory[]>) => any;
}

export const URL = "/api/showcase-products";

const useProductShowcaseData = (): UseProductShowcaseDataResult => {
  const { data, mutate } = useRequest<ShowcaseCategory[]>({
    url: URL,
  }, {
    errorRetryCount: 1
  });

  return { data, mutate };
};

export default useProductShowcaseData;
