import React from "react";
import Header from "@Components/layouts/Header";
import { Hero, HeroProvider } from "@Components/layouts/Hero";
import { ProductCategoryShowcase } from "@Components/product/ProductCategoryShowcase";
import { ShowcaseCategory, URL } from "@Hooks/useProductShowcaseData";
import { getShowcaseProducts } from "./api/showcase-products";
import { SWRConfig } from "swr";
import { NextPage } from "next";
import { makeResponse } from "@Hooks/useRequest";
import { isAxiosError } from "@Lib/request";

export async function getStaticProps() {
  let productShowcase: ShowcaseCategory[] = [];
  console.log(
    "Fetching product showcase data from:",
    process.env.APIS__PUBLIC_WEBSITE + URL,
  );
  try {
    productShowcase = await getShowcaseProducts();
  } catch (err) {
    if (isAxiosError(err)) {
      console.error(
        "Error getting product showcase fallback data",
        err.message,
        err.config,
      );
    }
    
  }

  let req = {
    url: URL,
  };

  return {
    props: {
      fallback: {
        [JSON.stringify(req)]: makeResponse(
          productShowcase,
          req,
          "Initial Data",
        ),
      },
    },
    revalidate: 60,
  };
}

interface IndexPageProps {
  fallback: {
    [url: string]: any;
  };
}

const IndexPage: NextPage<IndexPageProps> = ({ fallback }) => {
  return (
    <>
      <SWRConfig value={{ fallback }}>
        <Header />
        <HeroProvider>
          <Hero />
          <ProductCategoryShowcase />
        </HeroProvider>
      </SWRConfig>
    </>
  );
};

export default IndexPage;
