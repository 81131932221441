import useCart from "@Hooks/useCart";
import {
  ButtonGroup,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  GridSize,
} from "@material-ui/core";
import {
  makeStyles,
  styled,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import { Product } from "@Types";
import { formatValue } from "@Utils/stringUtils";
import React from "react";
import ProductListItemTitle, { ProductItemType } from "./ProductListItemTitle";
import { useRouter } from "next/router";
import clsx from "clsx";
import { FontWeight } from "theme/fonts";

interface ProductListItemProps {
  item: Product;
  isSmall?: boolean;
}

type ProductListItemSize = Omit<ProductListItemProps, "item">;

interface ProductGridSizes {
  productTitle: GridSize;
  productPrice: GridSize;
}

const getGridFromSize = (isSmall?: boolean): ProductGridSizes => {
  // only md sizes need adjustments
  // so the md sizing will be referenced by grid name
  const gridSizes: ProductGridSizes = {
    productTitle: 4,
    productPrice: 5,
  };

  if (isSmall) {
    gridSizes.productTitle = 12;
    gridSizes.productPrice = 12;
  }

  return gridSizes;
};

const ProductListItemText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.grey[600],
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 0, 1),
      minHeight: 48,
    },
    itemHeader: ({ isSmall }: ProductListItemSize) => ({
      alignContent: "center",
      backgroundColor: theme.palette.grey[50],
      padding: theme.spacing(0, 2),
      display: !isSmall ? "flex" : "block",
      minHeight: 48,
    }),
    justifyStart: ({ isSmall }: ProductListItemSize) => ({
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        justifyContent: !isSmall ? "flex-start" : undefined,
      },
    }),
    justifyEnd: ({ isSmall }: ProductListItemSize) => ({
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        justifyContent: !isSmall ? "flex-end" : undefined,
      },
    }),
    itemPrice: ({ isSmall }: ProductListItemSize) => ({
      fontWeight: FontWeight.Bold,
      fontSize: isSmall ? "0.675rem" : "0.875rem",
      marginRight: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        fontSize: "0.875rem",
      },
    }),
    itemButton: ({ isSmall }: ProductListItemSize) => ({
      // this portion needs to be forced when size is small
      width: "100%",
      maxWidth: 450,
      marginBottom: theme.spacing(1),
      fontSize: "0.725rem",
      [theme.breakpoints.up("md")]: {
        width: isSmall ? "100%" : undefined,
        marginBottom: isSmall ? theme.spacing(1) : 0,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.button.fontSize,
      },
    }),
  }),
);

const useCheckoutRemoveStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonGroup: (prop: any) => ({
      maxWidth: 450,
      maxHeight: 28,
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        marginBottom: prop.isSmall ? theme.spacing(1) : 0,
      },
      [theme.breakpoints.up("lg")]: {
        maxHeight: 32,
      },
    }),
    button: {
      flexGrow: 1,
      fontSize: "0.725rem",
      flexBasis: "auto",
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.button.fontSize,
      },
    },
    icon: {
      flexGrow: 0,
      flexShrink: 1,
      flexBasis: "auto",
      maxWidth: 45,
    },
  }),
);

const CheckoutRemoveButton = ({ item, isSmall }: ProductListItemProps) => {
  const { remove: removeFromCart } = useCart();
  const classes = useCheckoutRemoveStyles({ isSmall });
  const { push } = useRouter();

  return (
    <ButtonGroup
      size="small"
      className={classes.buttonGroup}
      variant="outlined"
      fullWidth
      color="secondary"
    >
      <Button
        onClick={() => push({ pathname: "/cart" })}
        className={classes.button}
      >
        Checkout
      </Button>
      <Button
        className={clsx(classes.button, classes.icon)}
        onClick={() => removeFromCart(item)}
        size="small"
      >
        <CancelIcon />
      </Button>
    </ButtonGroup>
  );
};

const ProductListItem = ({ item, isSmall }: ProductListItemProps) => {
  const { add: addToCart, inCart } = useCart();

  const itemTypePattern = /basic|enhanced|plus/i;

  const itemTypeMatches = item.Name.match(itemTypePattern);
  const gridSizes = getGridFromSize(isSmall);
  const classes = useStyles({ isSmall });

  const itemType: ProductItemType | undefined = itemTypeMatches
    ? (itemTypeMatches[0] as ProductItemType)
    : undefined;

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const isInCart = inCart(item);

  return (
    <Grid container key={item.Id} className={classes.root}>
      <Grid
        item
        xs={12}
        container
        spacing={mdUp && !isSmall ? 0 : 1}
        alignItems="center"
        justifyContent="center"
        className={classes.itemHeader}
        direction={mdUp && !isSmall ? "row" : "column"}
        wrap="nowrap"
      >
        <Grid
          item
          container
          xs={12}
          md={gridSizes.productTitle}
          justifyContent={mdUp && !isSmall ? "flex-start" : "center"}
        >
          <ProductListItemTitle
            title={item.Name}
            itemType={itemType}
            isSmall={isSmall}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={gridSizes.productPrice}
          style={{ paddingRight: mdUp && !isSmall ? 2 : 0 }}
          container
          justifyContent={mdUp && !isSmall ? "flex-end" : "center"}
          alignItems="center"
        >
          <Typography
            component="span"
            variant="body1"
            className={classes.itemPrice}
            display="inline"
          >
            {item.IsMedical
              ? "Starting at $63.25"
              : item.Price
              ? formatValue(item.Price).asMoney()
              : ""}
          </Typography>
        </Grid>

        <Grid
          item
          container
          justifyContent={mdUp && !isSmall ? "flex-end" : "center"}
          xs
          md={!isSmall && 3}
        >
          {!isInCart ? (
            <Button
              size="small"
              className={classes.itemButton}
              variant="contained"
              color="secondary"
              onClick={() => addToCart(item)}
            >
              Add to cart
            </Button>
          ) : (
            <CheckoutRemoveButton item={item} isSmall={isSmall} />
          )}
        </Grid>
      </Grid>
      {!isSmall && (
        <Grid item xs={12}>
          <ProductListItemText variant="body1">
            {item.Description}
          </ProductListItemText>
        </Grid>
      )}
    </Grid>
  );
};

export default ProductListItem;
