import TextOverlayLg from "/public/Large/Text.png";
import TextOverlayMd from "/public/Medium/Text.png";
import TextOverlaySm from "/public/Small/Text.png";

import DefaultLg from "/public/Large/Default.png";
import DefaultMd from "/public/Medium/Default.png";
import DefaultSm from "/public/Small/Default.png";

import SubrogationLg from "/public/Large/Subrogation.png";
import SubrogationMd from "/public/Medium/Subrogation.png";
import SubrogationSm from "/public/Small/Subrogation.png";

import WorkLg from "/public/Large/Work.png";
import WorkMd from "/public/Medium/Work.png";
import WorkSm from "/public/Small/Work.png";

import LiabilityLg from "/public/Large/Liability.png";
import LiabilityMd from "/public/Medium/Liability.png";
import LiabilitySm from "/public/Small/Liability.png";

import InjuryLg from "/public/Large/Injury.png";
import InjuryMd from "/public/Medium/Injury.png";
import InjurySm from "/public/Small/Injury.png";

export const imageMap = {
  text: {
    sm: TextOverlaySm,
    md: TextOverlayMd,
    lg: TextOverlayLg
  },
  Liability: {
    lg: LiabilityLg,
    md: LiabilityMd,
    sm: LiabilitySm
  },
  "Prior Injuries": {
    lg: InjuryLg,
    md: InjuryMd,
    sm: InjurySm
  },
  "Workers' Compensation": {
    lg: WorkLg,
    md: WorkMd,
    sm: WorkSm
  },
  "Subrogation": {
    lg: SubrogationLg,
    md: SubrogationMd,
    sm: SubrogationSm   
  },
  default: {
    lg: DefaultLg,
    md: DefaultMd,
    sm: DefaultSm
  }
};

